<template>
  <div>
    <div class="backgroundOV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Einmaleins – Übergang Diagnose</v-card-title
        >
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Welche Facetten des Hintergrundwissens konnten beim Einmaleins
          ausgemacht werden?
        </v-card-title>
        <v-card-text>
          Das Hintergrundwissen im Bereich Einmaleins lässt sich aufgliedern in
          die drei Facetten:
          <ul>
            <li>Kenntnis der Kernaufgaben</li>
            <li>Nutzung von Ableitungsstrategien</li>
            <li>Vernetzung von Aufgaben und Darstellungen</li>
          </ul>
          Das Wissen um diese drei Teilbereiche ist vor allem für die
          Diagnose und Förderung der Kinder wichtig, um zum einen
          diagnostizieren zu können, wo genau beispielsweise Probleme auftreten
          und zum anderen daran anschließend passgenaue Förderung anzustreben.
        </v-card-text>
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Was erwartet Sie nun auf dieser Grundlage im Bereich Diagnose und
          Förderung?
        </v-card-title>
        <v-card-text>
          In mehreren Aufgaben betrachten Sie gleich Lösungen von Kindern, um
          <ul>
            <li>typische Fehler im Bereich des Einmaleins zu gruppieren,</li>
            <li>Fehler im Bereich des Einmaleins zu diagnostizieren und</li>
            <li>Förderaufgaben für Kinder diagnosegeleitet auszuwählen.</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
    <AppBottomNavEME
      next="/einmaleins/diagnose-und-foerderung"
      back="/einmaleins/hintergrundwissen/aufgaben-und-darstellungen-vernetzen"
    />
  </div>
</template>

<script>
import AppBottomNavEME from "@/common/AppBottomNavEME";
export default {
  components: {
    AppBottomNavEME,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
